import React, { useState, useEffect } from 'react';
import "./App.css";
import logo from "./Logo_white.jpg"; // Placeholder for the logo image
import homePageBackground from "./Home_Page_Background.png";
import manWithMobile from "./MobileRequest.png"; // Placeholder for the man sitting with mobile image
import carImage from "./Car_about.png"; // Placeholder for the car image
import starImage from "./Star_6_about.png"; // Placeholder for the star image
import feature1 from "./Feature_1.jpg"; // Placeholder for Feature 1 image
import feature2 from "./Feature_2.jpg"; // Placeholder for Feature 2 image
import feature3 from "./Feature_3.jpg"; // Placeholder for Feature 3 image
import feature4 from "./Feature_4.jpg"; // Placeholder for Feature 4 image
import feature5 from "./Feature_5.jpg"; // Placeholder for Feature 5 image
import feature6 from "./Feature_6.jpg"; // Placeholder for Feature 6 image
import carStep1 from "./Car_How_it_works.png";
import vector1 from "./Vector_0.png";
import carStep2 from "./Car_How_it_works1.png";
import carStep3 from "./Car_How_it_works2.png";
import vector3 from "./Vector_2.png";
import carStep4 from "./Car_How_it_works3.png";
import vector4 from "./Vector_3.png";
import carStep5 from "./Car_How_it_works_4.png";
import arrowRight from './Arrow.jpg';
import valetImage from './Car_Free_Demo.png';
import freeIcon from './Free_pack.jpg';  // Adjust the path to your icon images
import starterIcon from './Starter_pack.jpg';
import businessIcon from './Buisiness_pack.jpg';
import enterpriseIcon from './Enterprise_pack.jpg';
import shareOtpImage from './Share_otp_image.png';
import priceCardFeatureTickIcon from './Price_Card_Features_Tick_Mark.png';
import footerLogo from "./Logo_blue.jpg";
import email_icon from "./Email_icon.png";
import phone_icon from "./Phone_icon.png";
import location_icon from "./Location_icon.png";
import facebook_icon from './Facebook.png';
import twitter_icon from './Twitter.png';
import instagram_icon from './Instagram.png';
import linkedIn_icon from './LinkedIn.png';
import youtube_icon from './YouTube.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'; 
import { IoIosArrowDropleft,IoIosArrowDropright } from "react-icons/io";
import ForemData from 'form-data';
import Mailgun from 'mailgun.js';

function App() {

  const [message, setMessage] = useState('Hi');
  const [status, setStatus] = useState('');
  const isEmailEnabled =true;
  const pricingPlanOn =false;

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <IoIosArrowDropleft />, // Pass custom next arrow
    prevArrow: <IoIosArrowDropright />, // Pass custom prev arrow
  }

  const initialFormData = {
    firstName: '',
    lastName: '',
    name: '',
    phone: '',
    email: '',
    message: ''
  };

  const [formData, setFormData] = useState(initialFormData);

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => setMenuOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Further actions to be decided later (API call, etc.)
  };

  const apiKey = process.env.REACT_APP_API_KEY;
  const emailFrom = process.env.REACT_APP_EMAIL_FROM;
  const emailTo = process.env.REACT_APP_EMAIL_TO;
  const mailgun = new Mailgun(ForemData);
  const mg = mailgun.client({ username: 'api', key: apiKey });
  const emailMessageContent = `
  Hi,
  
  Here are the details of the user who requested to contact us:

  Name: ${formData.name}
  Email: ${formData.email}
  Message: ${formData.message}
  
  Regards,
  Valet Hook Team
  `;
  const emailDemoRequestContent = `
  Hi,
  
  Here are the details of the user who requested for Demo:

  Name: ${formData.firstName} ${formData.lastName}
  Phone: ${formData.phone}
  Email: ${formData.email}
  Message: ${formData.message}
  
  Regards,
  Valet Hook Team
  `;
  const handleDemoRequestClick = () => {
    if (isEmailEnabled) {
        mg.messages.create('valethook.com', {
        from: `Valet Hook <${emailFrom}>`,
        to: emailTo,
        subject: 'Valet Hook Demo Request',
        text: emailDemoRequestContent
      });
    }
    setFormData(initialFormData);
  }
  const handleSendMailClick = () => {
    if (isEmailEnabled) {
        mg.messages.create('valethook.com', {
        from: `Valet Hook <${emailFrom}>`,
        to: emailTo,
        subject: 'Valet Hook Conatct Request',
        text: emailMessageContent
      });
    }
    setFormData(initialFormData);
  }
  
  const [conatctformData, setContactFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleConatctChange = (e) => {
    const { name, value } = e.target;
    setContactFormData({ ...formData, [name]: value });
  };

  const handleContactSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  const [selectedPlan, setSelectedPlan] = useState(2); // Default selected is the third card

  const [isMobile, setIsMobile] = useState(false);
  const [touchStartX, setTouchStartX] = useState(null);

  const plans = [
    {
      title: 'Basic',
      icon: freeIcon,
      price: '$9.99',
      timelane: '/ month',
      features: ['Basic Parking management tools', 'Up to 5 jobs per month'],
      feature_tick_icon: priceCardFeatureTickIcon,
    },
    {
      title: 'Standard',
      icon: starterIcon,
      price: '$19.99',
      timelane: '/ month',
      features: ['Basic Parking management tools', 'Up to 20 jobs per month'],
      feature_tick_icon: priceCardFeatureTickIcon,
    },
    {
      title: 'Premium',
      icon: businessIcon,
      price: '$29.99',
      timelane: '/ month',
      features: ['Advanced parking management tools', 'Up to 100 jobs per month', 'Customer feedback and payment integration'],
      feature_tick_icon: priceCardFeatureTickIcon,
    },
    {
      title: 'Enterprise',
      icon: enterpriseIcon,
      price: '$49.99',
      timelane: '/ month',
      features: ['Unlimited jobs', 'Full integration and API access', 'Dedicated support and custom features'],
      feature_tick_icon: priceCardFeatureTickIcon,
    },
  ];

  // Detect if the user is on a mobile device
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize(); // Call on load to set initial state
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handle swipe logic
  const handleSwipeStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleSwipeMove = (e) => {
    if (!touchStartX) return;
    const touchEndX = e.touches[0].clientX;

    if (touchStartX - touchEndX > 50) {
      // Swipe left
      handleSwipe('left');
    }

    if (touchStartX - touchEndX < -50) {
      // Swipe right
      handleSwipe('right');
    }
  };

  const handleSwipe = (direction) => {
    if (direction === 'left') {
      setSelectedPlan((prev) => (prev + 1) % plans.length); // Swipe to next card
    } else if (direction === 'right') {
      setSelectedPlan((prev) => (prev - 1 + plans.length) % plans.length); // Swipe to previous card
    }
    setTouchStartX(null); // Reset touch start value
  };

  return (
    <div className="App">
      {/* Navbar Section Laptop */}
      {!isMobile && (
        <nav className="navbar">
        <div className="navbar-container">
          <div className="logo-container">
            <img src={logo} alt="Valet Hook Logo" className="logo" />
            <span className="logo-text"></span>
          </div>
          <ul className="nav-links">
            <li><a href="#home">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#features">Features</a></li>
            <li><a href="#how_it_works">How it works</a></li>
            <li><a href="#free_demo_request">Free Demo Request</a></li>
            {/* <li><a href="#pricing">Pricing</a></li> */}
            <li><a href="#contact_details">Conatct Us</a></li>
          </ul>
          <a href="#contact_details"> <button className="get-started-Demo-btn">Request a Free Demo</button> </a>
        </div>
      </nav>
      )}
      {/* Navbar Section Mobile*/}
      {isMobile && (<nav className="navbar">
        <div className="navbar-container">
          <div className="logo-container-mobile">
            <img src={logo} alt="Valet Hook Logo" className="logo" />
          </div>
          <button className="menu-icon" onClick={toggleMenu}>
            &#9776; 
          </button>
        </div>
      </nav> )}
      {/* Menu, sliding from the left */}
      {isMenuOpen && (
        <div className="menu-dashboard">
          <ul className="nav-links-mobile">
            <li><a href="#home" onClick={closeMenu}>Home</a></li>
            <li><a href="#about" onClick={closeMenu}>About</a></li>
            <li><a href="#features" onClick={closeMenu}>Features</a></li>
            <li><a href="#how_it_works" onClick={closeMenu}>How it works</a></li>
            {/* <li><a href="#pricing" onClick={closeMenu}>Pricing</a></li> */}
            <li><a href="#" onClick={closeMenu}>Free Demo Request</a></li>
          </ul>
        </div>
      )}

      {/* Close the menu if the user clicks outside */}
      {isMenuOpen && (
        <div className="overlay" onClick={closeMenu}></div>
      )}

      {/* Hero Section */}
      <section id='home' className="hero-section" style={{
        backgroundImage: `url(${homePageBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
          }}>
        {/* {isMobile && (<button className="get-started-btn-mobile">Get Started</button>)} */}
        <h1 className="hero-title">
          Effortless Valet Parking Management <br /> at Your Fingertips
        </h1>
        <p className="hero-description">
          Valet Hook simplifies valet parking for hotels, restaurants, and event
          venues—streamlining <br /> operations and enhancing customer satisfaction.
        </p>
        {/* <div className="hero-buttons">
          <button className="request-demo-btn">Request a Free Demo</button>
          <button className="contact-btn">Contact Us</button>
        </div> */}
      </section>

      {/* About Section */}
      <section id='about' className="about-section">
        <div className="about-container">
          {isMobile && <h2 className="about-heading">--- About ---</h2> }
          <div className="about-images">
            <img src={manWithMobile} alt="Man with Mobile" className="about-man-mobile" />
            <img src={carImage} alt="Car" className="about-car-image" />
            <img src={starImage} alt="Star" className="about-star-image" />
          </div>
          <div className="about-text-container">
            {!isMobile && <h2 className="about-heading">About --------</h2>}
            <h2 className="about-title">Valet services & Drivers</h2>
            <p className="about-description">
              Valet Hook is a cutting-edge solution designed to streamline valet
              parking services. We empower businesses to manage vehicle parking
              efficiently while improving customer experiences and increasing
              staff productivity.
            </p>
            <p className="about-subdescription">
              Effortlessly park your car with comfort and simplicity using our
              valet Hook.
            </p>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id='features' className="features-section">
        <div className="features-layout">
          <div className="features-text">
            <h1 className="features-heading">Features -------</h1>
            <h2 className="features-title">Features That Make Us the Best</h2>
            {!isMobile && <p className="features-description">
              Eliminate the stress of parking and leave your worries behind. Let our valets provide you with a seamless, stress-free experience.
            </p> }
            {!isMobile && <a href="#contact_details"> <button className="features-get-started-btn">Get Started</button> </a>}
          </div>

          {/* Right Side - Features Grid */}
          {!isMobile && (<div className="features-grid">
            <div className="feature-box">
              { <img src={feature1} alt="Feature 1" />}
              <h3>Real-Time Updates</h3>
              <p>Keep track of all parked cars in real-time, with instant notifications on vehicle status for both customers and staff.</p>
            </div>
            <div className="feature-box">
              {!isMobile && <img src={feature2} alt="Feature 2" />}
              <h3>Efficient Job Management</h3>
              <p>Easily create, assign, and monitor valet tasks with intuitive and efficient job management tools.</p>
            </div>
            <div className="feature-box">
              {!isMobile && <img src={feature3} alt="Feature 3" />}
              <h3>In-Platform Advertising</h3>
              <p>Boost your revenue with in-platform ads. Promote services directly to increase visibility and reach.</p>
            </div>
            <div className="feature-box">
              {!isMobile && <img src={feature4} alt="Feature 4" />}
              <h3>Contactless Customer Interaction</h3>
              <p>Customers can request their vehicles and provide feedback via the app, enhancing their valet experience.</p>
            </div>
            <div className="feature-box">
              {!isMobile && <img src={feature5} alt="Feature 5" />}
              <h3>Secure Payment Integration</h3>
              <p>Offer safe and secure digital payments, allowing customers to pay for valet services seamlessly.</p>
            </div>
            <div className="feature-box">
              {!isMobile && <img src={feature6} alt="Feature 6" />}
              <h3>Multi-Location Management</h3>
              <p>Effortlessly manage multiple valet locations from a single platform, with real-time insights in parking.</p>
            </div>
          </div> )}
          {isMobile && (<div>
            <div className="feature-box-mobile">
              <div className="feature-box-header">
                <img src={feature1} alt="Feature 1" />
                <h3>Real-Time Updates</h3>
              </div>
              <p>
                Keep track of all parked cars in real-time, with instant notifications on
                vehicle status for both customers and staff.
              </p>
            </div>
            <div className="feature-box-mobile">
              <div className="feature-box-header">
                <img src={feature2} alt="Feature 2" />
                <h3>Efficient Job Management</h3>
              </div>
              <p>
                Easily create, assign, and monitor valet tasks with intuitive and efficient job management tools.
              </p>
            </div>
            <div className="feature-box-mobile">
              <div className="feature-box-header">
                <img src={feature3} alt="Feature 3" />
                <h3>In-Platform Advertising</h3>
              </div>
              <p>
                Boost your revenue with in-platform ads. Promote services directly to increase visibility and reach.
              </p>
            </div>
            <div className="feature-box-mobile">
              <div className="feature-box-header">
                <img src={feature4} alt="Feature 4" />
                <h3>Contactless Customer Interaction</h3>
              </div>
              <p>
                Customers can request their vehicles and provide feedback via the app, enhancing their valet experience.
              </p>
            </div>
            <div className="feature-box-mobile">
              <div className="feature-box-header">
                <img src={feature5} alt="Feature 5" />
                <h3>Secure Payment Integration</h3>
              </div>
              <p>
                Offer safe and secure digital payments, allowing customers to pay for valet services seamlessly.
              </p>
            </div>
            <div className="feature-box-mobile">
              <div className="feature-box-header">
                <img src={feature6} alt="Feature 6" />
                <h3>Multi-Location Management</h3>
              </div>
              <p>
              Effortlessly manage multiple valet locations from a single platform, with real-time insights in parking.
              </p>
            </div>
            {/* <div className="feature-box-mobile">
              {!isMobile && <img src={feature2} alt="Feature 2" />}
              <h3>Efficient Job Management</h3>
              <p>Easily create, assign, and monitor valet tasks with intuitive and efficient job management tools.</p>
            </div> */}
            {/* <div className="feature-box-mobile">
              {!isMobile && <img src={feature3} alt="Feature 3" />}
              <h3>In-Platform Advertising</h3>
              <p>Boost your revenue with in-platform ads. Promote services directly to increase visibility and reach.</p>
            </div> */}
            {/* <div className="feature-box-mobile">
              {!isMobile && <img src={feature4} alt="Feature 4" />}
              <h3>Contactless Customer Interaction</h3>
              <p>Customers can request their vehicles and provide feedback via the app, enhancing their valet experience.</p>
            </div> */}
            {/* <div className="feature-box-mobile">
              {!isMobile && <img src={feature5} alt="Feature 5" />}
              <h3>Secure Payment Integration</h3>
              <p>Offer safe and secure digital payments, allowing customers to pay for valet services seamlessly.</p>
            </div> */}
            {/* <div className="feature-box-mobile">
              {!isMobile && <img src={feature6} alt="Feature 6" />}
              <h3>Multi-Location Management</h3>
              <p>Effortlessly manage multiple valet locations from a single platform, with real-time insights in parking.</p>
            </div> */}
          </div> )}
        </div>
      </section>
      {/* Fourth Page - How It Works Section */}
      <section id='how_it_works' className="how-it-works-section">
  <div>
    {!isMobile && <h2 className="how-it-works-heading">------ How It Works ------</h2>}
    {isMobile && <h2 className="how-it-works-heading">-- How It Works --</h2>}
  </div>
  <h2 className="how-valet-works-heading">How Valet Hook Works</h2>
  <div className="how-it-works-steps-wrapper">
    {/* First Row - First 3 Steps */}
    <div className="how-it-works-steps-row">
      <div className="step" style={{
        backgroundImage: `url(${vector1})`,
        backgroundSize: '150px 130px',
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat'
          }}>
        <img src={carStep1} alt="Step 1 - Handover Your Car" />
        <h3>Handover Your Car</h3>
        <p>Handover your car to the valet <br/> driver along with the basic details.</p>
      </div>
      { !isMobile && <img src={arrowRight} className="arrow" alt="Arrow" /> }
      <div className="step">
        <img src={carStep2} alt="Step 2 - Track Your Car" />
        <h3>Track Your Car</h3>
        <p>Open the link you receive to track <br/> the status of your car in real-time.</p>
      </div>
      { !isMobile && <img src={arrowRight} className="arrow" alt="Arrow" /> }
      <div className="step"  style={{
        backgroundImage: `url(${vector3})`,
        backgroundSize: '150px 130px',
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat'
          }}>
        <img src={carStep3} alt="Step 3 - Request Return" />
        <h3>Request Return</h3>
        <p>Make a return request while finishing <br/> your meal, keeping an eye on the <br/> estimated time.</p>
      </div>
      {/* {isMobile && <img src={arrowRight} className="arrow" alt="Arrow" />} */}
    </div>

    {/* Second Row - Last 2 Steps */}
    <div className="how-it-works-steps-row">
      <div className="step-4"  style={{
        backgroundImage: `url(${vector4})`,
        backgroundSize: '150px 120px',
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
          }}>
        <img className="share-otp-image" src={shareOtpImage} alt="Step 4 - Pick Up Your Car" />
        <img className="car-step-image" src={carStep4} alt="Step 4 - Pick Up Your Car" />
        <h3>Pick Up Your Car</h3>
        <p>Use OTP authentication to collect <br/> your car when it’s ready.</p>
      </div>
      { !isMobile && <img src={arrowRight} className="arrow" alt="Arrow" /> }
      <div className="step">
        <img src={carStep5} alt="Step 5 - Share Your Feedback" />
        <h3>Share Your Feedback</h3>
        <p>Give feedback and let us know about your experience.</p>
      </div>
    </div>
  </div>
</section>
      {/* Fifth Page - Free Demo Request Section */}
      <section id='free_demo_request' className="demo-request-section">
        <div className="demo-request-container">
          {/* Left Side Image */}
          {!isMobile && <div className="demo-image-container">
            <img src={valetImage} alt="Valet Hook Demo" />
          </div> }

          {/* Right Side Form */}
          <div className="demo-form-container">
            <h3 className="demo-heading">Free Demo Request</h3>
            <h1 className="demo-subheading">Request a Free Demo of Valet Hook</h1>
            <form onSubmit={handleSubmit}>
              {!isMobile &&<div className="form-group">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                />
              </div> }
              {isMobile && <div className="form-group-mobile">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                />
              </div> }
              {isMobile && <div className="form-group-mobile">
                <input
                  type="tel"
                  name="phone"
                  placeholder="Phone No."
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
              </div> }
              {isMobile && <div className="form-group-mobile">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div> }
              {!isMobile && <div className="form-group">
                <input
                  type="tel"
                  name="phone"
                  placeholder="Phone No."
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div> }
              <textarea
                name="message"
                placeholder="Message"
                value={formData.message}
                onChange={handleInputChange}
                rows="4"
                required
              />
              { !isMobile &&<button type="submit" className="request-demo-button" onClick={handleDemoRequestClick}>
                Request Demo
              </button> }
            </form>
            { isMobile && <button type="submit" className="request-demo-button" onClick={handleDemoRequestClick}>
                Request Demo
            </button> }
          </div>
        </div>
      </section>
      {/* Fifth Page - pricing Section */}
      { !isMobile && pricingPlanOn && ( <section id='pricing' className="pricing-section">
        <h2 className="pricing-title">------   Pricing Plans Section   ------</h2>
        <p className="pricing-subtitle">Affordable Plans to Suit Business</p>
        <div className="card-slider">
          {plans.map((plan, index) => (
            <div
              key={index}
              className={`pricing-card ${index === selectedPlan ? 'selected' : ''}`}
              onClick={() => setSelectedPlan(index)}
            >
              <div className={`card-header ${index === selectedPlan ? 'selected' : ''}`}>
                <h3>{plan.title}</h3>
                <img src={plan.icon} alt={`${plan.title} icon`} />
              </div>
              <div className="card-price">
                <span className={`card-price-amount ${index === selectedPlan ? 'selected' : ''}`}>{plan.price}</span>
                <span className={`card-price-per ${index === selectedPlan ? 'selected' : ''}`}>{plan.timelane}</span>
              </div>
              <button className="prizes-get-started-btn">Get Started Now</button>
              <ul className={`card-features ${index === selectedPlan ? 'selected' : ''}`}>
                {plan.features.map((feature, i) => (
                  <li key={i}>
                    <span><img className='tick-mark-icon' src={plan.feature_tick_icon} alt={`${plan.title} icon`} /></span> {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>) }

      {/* Slider Pricing Section */}
      {/* Fifth Page - pricing Section */}
      { isMobile && pricingPlanOn && (<section id='pricing' className="pricing-section">
        <h2 className="pricing-title">---- Pricing Plans ----</h2>
        <p className="pricing-subtitle-mobile">Affordable Plans to SuitBusiness</p>
        <div className='w-3/4 m-auto'>
          <div className='mt-20'>
            <Slider {...settings}>
              {plans.map((plan, index) => (
                <div
                  key={index}
                  className={`pricing-card-mobile ${index === selectedPlan ? 'selected' : ''}`}
                  onClick={() => setSelectedPlan(index)}
                >
                  <div className={`card-header ${index === selectedPlan ? 'selected' : ''}`}>
                    <h3>{plan.title}</h3>
                    <img src={plan.icon} alt={`${plan.title} icon`} />
                  </div>
                  <div className="card-price">
                    <span className={`card-price-amount ${index === selectedPlan ? 'selected' : ''}`}>{plan.price}</span>
                    <span className={`card-price-per ${index === selectedPlan ? 'selected' : ''}`}>{plan.timelane}</span>
                  </div>
                  <button className="prizes-get-started-btn">Get Started Now</button>
                  <ul className={`card-features ${index === selectedPlan ? 'selected' : ''}`}>
                    {plan.features.map((feature, i) => (
                      <li key={i}>
                        <span><img className='tick-mark-icon' src={plan.feature_tick_icon} alt={`${plan.title} icon`} /></span> {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </Slider>  
          </div>
        </div>
      </section> )}

      {/* Contact Us Section */}
      <section id='contact_details' className="contact-us-container">
        {!isMobile && (<div className="contact-us-section">
          <div className="left-section">
            <h4 className="contact-heading">Contact us ------</h4>
            <h1 className="connect-heading">Connect with us</h1>
            <p className="contact-description">
              We’d love to hear from you! Whether you have questions or want to learn more about Valet Hook, feel free to reach out.
            </p>
            <div className="contact-info">
              <p><span><img className='email-sym-icon' src={email_icon}/></span> contact@valethook.com</p>
              {/* <p><span><img className='phone-sym-icon' src={phone_icon} /></span> (414) 687 - 5892</p> */}
              <p><span><img className='location-sym-icon' src={location_icon} /></span> Financial district , Hyderabad</p>
            </div>
          </div>
          <div className="right-section">
            <form onSubmit={handleSubmit}>
              <div className="form-group-conatct">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group-conatct">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="you@company.com"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group-conatct">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </div>
              <button type="submit" className="submit-button" onClick={handleSendMailClick}>Send message</button>
            </form>
          </div> 
        </div> )}


        {isMobile && (<div className="contact-us-section">
            <h4 className="contact-heading">Contact us</h4>
            <h1 className="connect-heading">Connect with us</h1>
            <p className="contact-description">
              We’d love to hear from you! Whether you have questions or want to learn more about Valet Hook, feel free to reach out.
            </p>
            <form onSubmit={handleSubmit}>
            {isMobile && <div className="form-group-mobile">
                <input
                  type="tel"
                  name="phone"
                  placeholder="Name."
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
              </div> }
              {isMobile && <div className="form-group-mobile">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div> }
              <div className="form-group">
                <textarea
                  id="message"
                  name="message"
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </div>
              <button type="submit" className="submit-button" onClick={handleSendMailClick}>Send message</button>
            </form>
        </div> )}
        

      </section>
      {/* Footer Section */}
      <section className="footer-container">
        {!isMobile && <div className="footer-left">
          <img src={footerLogo} alt="Valet Hook Logo" className="footer-logo" />
          <p className="footer-description">
            Seamless Parking Experience at Your Command. No Waiting, Get Ready Before You Arrive.
          </p>
          {/* <p className="footer-social-heading">Social Media Links</p>
          <div className="social-icons">
            <p><span><img className='social-media-facebook-icon' src={facebook_icon}/></span> 
              <span><img className='social-media-twitter-icon' src={twitter_icon}/></span>
              <span><img className='social-media-instagram-icon' src={instagram_icon}/></span>
              <span><img className='social-media-linkedIn-icon' src={linkedIn_icon}/></span>
              <span><img className='social-media-youtube-icon' src={youtube_icon}/></span>
            </p>
          </div> */}
        </div>}

        {!isMobile && <div className="footer-middle">
          <h4 className="footer-heading">Quick links</h4>
          <ul className="footer-links">
            <li><a href="#">Home</a></li>
            <li><a href="#">Features</a></li>
            {/* <li><a href="#">Pricing</a></li> */}
            <li><a href="#">Contact</a></li>
            <li><a href="#">Free Demo Request</a></li>
            <li><a href="#">Privacy Policy</a></li>
          </ul>
        </div> }

        <div className="footer-right">
          <h4 className="footer-right-heading">Contacts us</h4>
          <p><span><img className='email-sym-icon' src={email_icon}/></span> contact@valethook.com</p>
          {/* <p><span><img className='phone-sym-icon' src={phone_icon} /></span> (414) 687 - 5892</p> */}
          <p><span><img className='location-sym-icon' src={location_icon} /></span> Financial district , Hyderabad</p>
          {isMobile && <img src={footerLogo} alt="Valet Hook Logo" className="footer-logo-mobile" />}
        </div>
      </section>

      <footer className="footer-bottom">
        <p>© 2023-2024 Valethook, All Rights Reserved</p>
        <p>Made with love in India</p>
      </footer>
    </div>
  );
}

export default App;
